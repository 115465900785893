import { action } from "typesafe-actions";
import { IBusinessTask, IEmailConversationActivity, ISMSActivity, IWhatsAppActivity } from "./activity.types";
import { IDataTableV2DateState } from "../../components/TableV2/preDefinedPlugins/DataTableV2Date/DataTableV2Date.types";
import { IDatatableV2AdvancedSearchFilter } from "../../components/TableV2/preDefinedPlugins/SearchFilter/SearchFilter.types";
import { ThunkAction } from "redux-thunk";
import { IStoreState } from "../initialStoreState";
import { AnyAction } from "redux";
import { api } from "../../api/api";
import { saveLoaderCompleted, saveLoaderProgress, showMessage } from "../messages/messagesActions";
import { ERROR_MESSAGES } from "../../constants/enums";
import { makeApiCall } from "../../helpers/postRequest";

export const FETCH_BUSINESS_TASKS_LIST_PROGRESS =
  "FETCH_BUSINESS_TASKS_LIST_PROGRESS";
export const FETCH_BUSINESS_TASKS_LIST_SUCCESS =
  "FETCH_BUSINESS_TASKS_LIST_SUCCESS";
export const FETCH_BUSINESS_TASKS_LIST_FAILED =
  "FETCH_BUSINESS_TASKS_LIST_FAILED";

export const fecthBusinessTasksListProgress = () =>
  action(FETCH_BUSINESS_TASKS_LIST_PROGRESS);
export const fecthBusinessTasksListSuccess = (
  data: IBusinessTask[],
  totalRecords: number
) => action(FETCH_BUSINESS_TASKS_LIST_SUCCESS, { data, totalRecords });
export const fecthBusinessTasksListFailed = () =>
  action(FETCH_BUSINESS_TASKS_LIST_FAILED);

export const fetchBusinessTasksListAsync =
  (
    pageNumber: number,
    rowsInPerPage: number,
    status: string,
    date: IDataTableV2DateState["dates"],
    searchValue: IDatatableV2AdvancedSearchFilter,
    moduleName: string,
    subModuleName: string,
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fecthBusinessTasksListProgress());
      let finalUrl = `/tasks/get-task-module-wise?pageNo=${pageNumber}&itemPerPage=${rowsInPerPage}&from_date=${date.fromDate}&to_date=${date.toDate}&module_name=${moduleName}&sub_module_name=${subModuleName}`;

      if (searchValue.length > 0) {
        finalUrl = `/tasks/get-task-module-wise??pageNo=${pageNumber}&itemPerPage=${rowsInPerPage}&from_date=${
          date.fromDate
        }&to_date=${date.toDate}&advanceFilter=${JSON.stringify(searchValue)}`;
      }
      if (status !== "-1") {
        finalUrl += "&status=" + status;
      }
      const res = await api.get(finalUrl);
      const data: IBusinessTask[] = res.data.data;
      const totalRecords = res.data.totalRecords;

      dispatch(fecthBusinessTasksListSuccess(data, totalRecords));
    } catch (err: any) {
      dispatch(fecthBusinessTasksListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const FETCH_BUSINESS_TASKS_PROGRESS = "FETCH_BUSINESS_TASKS_PROGRESS";
export const FETCH_BUSINESS_TASKS_SUCCESS = "FETCH_BUSINESS_TASKS_SUCCESS";
export const FETCH_BUSINESS_TASKS_FAILED = "FETCH_BUSINESS_TASKS_FAILED";

export const fetchBusinessTasksProgress = () =>
  action(FETCH_BUSINESS_TASKS_PROGRESS);
export const fetchBusinessTasksSuccess = (data: IBusinessTask[]) =>
  action(FETCH_BUSINESS_TASKS_SUCCESS, { data });
export const fetchBusinessTasksFailed = (errorMessage: string) =>
  action(FETCH_BUSINESS_TASKS_FAILED, { errorMessage });

export const fetchBusinessTasksAsync =
  (code: any): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchBusinessTasksProgress());
      const res = await api.get(
        `/tasks/get-task-module-wise?task_module_wise_code=${code}`
      );
      const data: IBusinessTask[] = res.data.data;
      if (data.length > 0) {
        dispatch(fetchBusinessTasksSuccess(data));
      } else {
        dispatch(fetchBusinessTasksFailed(ERROR_MESSAGES.NO_RECORD_FOUND));
      }
    } catch (err: any) {
      dispatch(fetchBusinessTasksFailed(ERROR_MESSAGES.SERVER_ERROR));
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

  export const upsertBusinessTask =
  (
    data: IBusinessTask,

    onCallback: (isSuccess: boolean) => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => { 
    try {
      dispatch(saveLoaderProgress());

        await makeApiCall(
          {
            url: "/tasks/create-task-module-wise",
            method: "POST",
          
          },
          {},
          {
            ...data,
          }
        );

        dispatch(
          showMessage({
            type: "success",
            message: "Task created successfully",
            displayAs: "snackbar",
          })
        );
        onCallback(true);
     
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };



/*------------*/
export const FETCH_EMAIL_CONVERSATION_LIST_PROGRESS =
  "FETCH_EMAIL_CONVERSATION_LIST_PROGRESS";
export const FETCH_EMAIL_CONVERSATION_LIST_SUCCESS =
  "FETCH_EMAIL_CONVERSATION_LIST_SUCCESS";
export const FETCH_EMAIL_CONVERSATION_LIST_FAILED =
  "FETCH_EMAIL_CONVERSATION_LIST_FAILED";

  export const fetchEmailConversationListProgress = () =>
    action(FETCH_EMAIL_CONVERSATION_LIST_PROGRESS);
  export const fetchEmailConversationListSuccess = (
    data: IEmailConversationActivity[],
  ) => action(FETCH_EMAIL_CONVERSATION_LIST_SUCCESS, { data });
  export const fetchEmailConversationListFailed = (errorMesssage: string) =>
    action(FETCH_EMAIL_CONVERSATION_LIST_FAILED, {errorMesssage});
  
  export const fetchEmailConversationListAsync =
    (
      code: string
    ): ThunkAction<void, IStoreState, {}, AnyAction> =>
    async (dispatch, getState) => {
      try {
        dispatch(fetchEmailConversationListProgress());
       
        const res = await api.get(
          `/general/get-email-history?module_code=${code}`
        );
        const data: IEmailConversationActivity[] = res.data.data;
        if (data.length > 0) {
          dispatch(fetchEmailConversationListSuccess(data));
        } else {
          dispatch(fetchEmailConversationListFailed(ERROR_MESSAGES.NO_RECORD_FOUND));
        }
      } catch (err: any) {
        dispatch(fetchEmailConversationListFailed(ERROR_MESSAGES.SERVER_ERROR));
        dispatch(
          showMessage({
            type: "error",
            message: err.response.data.message,
            displayAs: "snackbar",
          })
        );
      }
    };


/*------------*/
export const FETCH_SMS_CONVERSATION_LIST_PROGRESS =
  "FETCH_SMS_CONVERSATION_LIST_PROGRESS";
export const FETCH_SMS_CONVERSATION_LIST_SUCCESS =
  "FETCH_SMS_CONVERSATION_LIST_SUCCESS";
export const FETCH_SMS_CONVERSATION_LIST_FAILED =
  "FETCH_SMS_CONVERSATION_LIST_FAILED";

  export const fetchSMSConversationListProgress = () =>
    action(FETCH_SMS_CONVERSATION_LIST_PROGRESS);
  export const fetchSMSConversationListSuccess = (
    data: ISMSActivity[],
  ) => action(FETCH_SMS_CONVERSATION_LIST_SUCCESS, { data });
  export const fetchSMSConversationListFailed = (errorMesssage: string) =>
    action(FETCH_SMS_CONVERSATION_LIST_FAILED, {errorMesssage});
  
  export const fetchSMSConversationListAsync =
    (
      code: string
    ): ThunkAction<void, IStoreState, {}, AnyAction> =>
    async (dispatch, getState) => {
      try {
        dispatch(fetchSMSConversationListProgress());
       
        const res = await api.get(
          `/general/get-email-history?module_code=${code}`
        );
        const data: ISMSActivity[] = res.data.data;
        if (data.length > 0) {
          dispatch(fetchSMSConversationListSuccess(data));
        } else {
          dispatch(fetchSMSConversationListFailed(ERROR_MESSAGES.NO_RECORD_FOUND));
        }
      } catch (err: any) {
        dispatch(fetchSMSConversationListFailed(ERROR_MESSAGES.SERVER_ERROR));
        dispatch(
          showMessage({
            type: "error",
            message: err.response.data.message,
            displayAs: "snackbar",
          })
        );
      }
    };

/*-----------*/

export const FETCH_WHATSAPP_CONVERSATION_LIST_PROGRESS =
  "FETCH_WHATSAPP_CONVERSATION_LIST_PROGRESS";
export const FETCH_WHATSAPP_CONVERSATION_LIST_SUCCESS =
  "FETCH_WHATSAPP_CONVERSATION_LIST_SUCCESS";
export const FETCH_WHATSAPP_CONVERSATION_LIST_FAILED =
  "FETCH_WHATSAPP_CONVERSATION_LIST_FAILED";

  export const fetchWhatsAppConversationListProgress = () =>
    action(FETCH_WHATSAPP_CONVERSATION_LIST_PROGRESS);
  export const fetchWhatsAppConversationListSuccess = (
    data: IWhatsAppActivity[],
  ) => action(FETCH_WHATSAPP_CONVERSATION_LIST_SUCCESS, { data });
  export const fetchWhatsAppConversationListFailed = (errorMesssage: string) =>
    action(FETCH_WHATSAPP_CONVERSATION_LIST_FAILED, {errorMesssage});
  
  export const fetchWhatsAppConversationListAsync =
    (
      code: string
    ): ThunkAction<void, IStoreState, {}, AnyAction> =>
    async (dispatch, getState) => {
      try {
        dispatch(fetchWhatsAppConversationListProgress());
       
        const res = await api.get(
          `/general/get-email-history?module_code=${code}`
        );
        const data: IWhatsAppActivity[] = res.data.data;
        if (data.length > 0) {
          dispatch(fetchWhatsAppConversationListSuccess(data));
        } else {
          dispatch(fetchWhatsAppConversationListFailed(ERROR_MESSAGES.NO_RECORD_FOUND));
        }
      } catch (err: any) {
        dispatch(fetchWhatsAppConversationListFailed(ERROR_MESSAGES.SERVER_ERROR));
        dispatch(
          showMessage({
            type: "error",
            message: err.response.data.message,
            displayAs: "snackbar",
          })
        );
      }
    };

/*-----------*/

export const CLEAR_BUSINESS_TASKS_STATE = "CLEAR_BUSINESS_TASKS_STATE";
export const clearBusinessTasks = () => action(CLEAR_BUSINESS_TASKS_STATE);
