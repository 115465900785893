import moment from "moment";
import { LoadState } from "../../../constants/enums";
import { getUniqueId } from "../../../helpers";

export interface IAccountingInvoiceInformationState {
  list: IInvoiceInformation[];
  listLoading: LoadState;
  data: IInvoiceInformation;
  loading: LoadState;
  error: string | null;
  totalRecords: number;
}

export interface IInvoiceDetails {
  key: string;
  type: string | null;
  transaction_type: string | null;
  detail: string | null;
  description: string | null;
  amount: string;
  tax_one_percentage: string;
  tax_one_amount: string;
  tax_two_percentage: string;
  tax_two_amount: string;
  commission_percentage: string;
  commission_amount: string;
  house_percentage: string;
  house_amount: string;
  producer_one_percentage: string;
  producer_one_amount: string;
  producer_two_percentage: string;
  producer_two_amount: string;
  producer_three_percentage: string;
  producer_three_amount: string;
  branch_percentage: string;
  branch_amount: string;
  total_amount: string;
  status: string;
}

export interface IInvoiceInformation {
  invoice_id: string | null;
  invoice_number: string | null;
  invoice_due_date: string | null;
  payment_terms: string | null;
  accounting_information_id: string | null;
  customer_code: number | null;
  policy_id: string | null;
  policy_number: string ;
  type_of_policy: string;
  policy_transaction_type: string | null;
  policy_status: string | null;
  transaction_date: string | null;
  effective_date: string | null;
  default_tax_region: string | null;
  agency_or_direct_bill: string | null;
  insurer_code: string | null;
  premium_financed: string | null;
  financed_by: string | null;
  invoice_details: IInvoiceDetails[];
  sub_total_dollar: string | null;
  net_payable_dollar: string;
  tax_one_total: string | null;
  tax_two_total: string | null;
  commission_total: string | null;
  is_mannual_invoice: boolean;
  invoice_notes: string | null;
  invoice_footer_information: string | null;
  total_due_dollar: string | null;
  status: string;
  policy_type: string | null;
  financed_fee: string | null;
  insert_ts?: string | null;
  amount_due: string | null;
  amount_paid: string | null;

}

export const initialAccountingInvoiceInformationState: IAccountingInvoiceInformationState =
  {
    list: [],
    listLoading: LoadState.NotLoaded,
    data: {
      invoice_id: null,
      invoice_number: null,
      invoice_due_date: moment().format("MM-DD-YYYY").toString(),
      payment_terms: "Due on Receipt",
      accounting_information_id: null,
      customer_code: null,
      policy_id: null,
      policy_number: "",
      type_of_policy: "",
      is_mannual_invoice: false,
      policy_transaction_type: null,
      policy_status: null,
      transaction_date: moment().format("MM-DD-YYYY").toString(),
      effective_date: null,
      default_tax_region: null,
      agency_or_direct_bill: null,
      insurer_code: null,
      premium_financed: null,
      financed_by: null,
      invoice_details: [
        {
          key: getUniqueId(),
          type: null,
          transaction_type: null,
          detail: null,
          description: null,
          amount: "",
          tax_one_percentage: "",
          tax_one_amount: "",
          tax_two_percentage: "",
          tax_two_amount: "",
          commission_percentage: "",
          commission_amount: "",
          house_percentage: "",
          house_amount: "",
          producer_one_percentage: "",
          producer_one_amount: "",
          producer_two_percentage: "",
          producer_two_amount: "",
          producer_three_percentage: "",
          producer_three_amount: "",
          branch_percentage: "",
          branch_amount: "",
          total_amount: "",
          status: "ACTIVE"
        },
      ],
      sub_total_dollar: "0",
      net_payable_dollar: "0",
      tax_one_total: "0",
      tax_two_total: "0",
      commission_total: "0",
      invoice_notes: null,
      invoice_footer_information: null,
      total_due_dollar: "0",
      status: "ACTIVE",
      financed_fee: null,
      policy_type: null,
      amount_due: null,
      amount_paid: null,
    },
    loading: LoadState.NotLoaded,
    totalRecords: 0,
    error: null,
  };
