import {
  Avatar,
  Button,
  Grid,
  MenuItem,
  SelectChangeEvent,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";
import { DataTable } from "../../components/Table/DataTable";
import {
  IDataTableProps,
  MobileLogoRenderType,
  RenderType,
} from "../../components/Table/interfaces/IDataTableProps";

import { PageContainer } from "../../components/container/PageContainer";

import { PageTitleBar } from "../../components/PageTitleBar/PageTitleBar";

import { ExpressionTransformation } from "../../components/ExpressionTransformation/ExpressionTransformation";
import { StandadCard } from "../../components/Cards";
import {
  ControlledCustomSelect,
  CustomFormLabel,
} from "../../components/formsComponents";
import { useSelector } from "react-redux";
import { IStoreState } from "../../redux/initialStoreState";
import { useDispatchWrapper } from "../../hooks";
import { fetchExpressionMappingsAsync } from "../../redux/expressionTransformation/expressionTransformationActions";
import { LoadState } from "../../constants/enums";
import { IExpresssionTranform, initialExprressionTransformPayload } from "../../redux/expressionTransformation/expressionTransformation.types";
import { truncate } from "../../helpers";

export const CSIOMaping: React.FC = () => {
  const [open, setOpen] = React.useState<IExpresssionTranform | null>(
    null
  );
  const { data, loading } = useSelector(
    (storeState: IStoreState) => storeState.expressionTransformation.mappings
  );
  const [selectedTable, setSelectedTable] = React.useState("");
  const dispatch = useDispatchWrapper();

  const handleClose = () => {
    setOpen(null);
  };

  const handleTableChange = (event: SelectChangeEvent<unknown>) => {
    setSelectedTable(event.target.value as string);
  };

  const handleSearchClick = () => {
    dispatch(fetchExpressionMappingsAsync(selectedTable));
  };

  const handleComplete= () => {
    dispatch(fetchExpressionMappingsAsync(selectedTable));
    handleClose();
  }

  const tableProps: IDataTableProps = {
    isPagination: false,
    isDataLoading: loading === LoadState.InProgress,
    columns: [
      {
        key: "columnName",
        headerName: "Column Name",
        fieldName: "column_name",
        renderType: RenderType.TEXT_DARK,
      },
      {
        key: "mapping",
        headerName: "Mapping",
        fieldName: "columnName",
        renderType: RenderType.CUSTOM_RENDER,
        onRowCellRender: (value, row: IExpresssionTranform) => {
          return (
            <Button variant="text"
           
            onClick={() => setOpen(row.eval_string ? row: {...initialExprressionTransformPayload, column_name: row.column_name})}>
              {truncate(row.eval_string || "Create expression",80)}
            </Button>
          );
        },
      },
    ],
    items: data,
  };

  return (
    <>
      <PageContainer title="View Csio" description="this is innerpage">
        <PageTitleBar heading="CSIO" />
        {/* <Button variant="contained" onClick={handleToggle}>Mapping Open</Button> */}
        <StandadCard sx={{ minHeight: 300 }}>
          <Grid container spacing={1}>
            <Grid item xs={4}>
              <Typography variant="h5" fontWeight={600} mb={1}>
                Choose Module
              </Typography>
              <Stack
                direction={"row"}
                alignItems={"center"}
                justifyContent={"space-between"}
                spacing={1}
              >
                <ControlledCustomSelect
                  fullWidth
                  value={selectedTable}
                  options={[
                    {
                      label: "CSIO Habitational Application EDI",
                      value: "csio_habitational_application_edi",
                    },
                    {
                      label: "CSIO Automobule Application EDI",
                      value: "csio_automobile_application_edi",
                    },
                  ]}
                  displayEmpty
                  placeholder="Select one"
                  onChange={handleTableChange}
                />
                <Button variant="contained" onClick={handleSearchClick}>
                  Search
                </Button>
              </Stack>
            </Grid>
            <Grid item xs={12} mt={2}>
              {(data.length > 0 || loading === LoadState.InProgress) && <DataTable {...tableProps} />}
            </Grid>
          </Grid>
        </StandadCard>
      </PageContainer>
      {open && (
        <ExpressionTransformation
          open={true}
          data={open}
          onClose={handleClose}
          onComplete={handleComplete}
        />
      )}
    </>
  );
};
