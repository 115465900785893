import produce from "immer";
import { IStoreState } from "../initialStoreState";
import { LoadState } from "../../constants/enums";
import { IUserProfileActions } from ".";
import { initialSecurityGroup, initialUserProfileState } from "./userprofileState";
import {
  FETCH_USERS_FAILED,
  FETCH_USERS_LIST_FAILED,
  FETCH_USERS_LIST_PROGRESS,
  FETCH_USERS_LIST_SUCCESS,
  FETCH_MY_PROFILE_FAILED,
  FETCH_MY_PROFILE_PROGRESS,
  FETCH_MY_PROFILE_SUCCESS,
  FETCH_USERS_PROGRESS,
  FETCH_USERS_SUCCESS,
  CLEAR_MY_PROFILE,
  FETCH_USERS_SECURITY_GROUP_PROGRESS,
  FETCH_USERS_SECURITY_GROUP_SUCCESS,
  FETCH_USERS_SECURITY_GROUP_FAILED,
} from "./userProfileActions";

export const UserProfileReducer = (
  state: IStoreState["userprofile"] = initialUserProfileState,
  action: IUserProfileActions
) => {
  switch (action.type) {
    case FETCH_USERS_LIST_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.users.loading = LoadState.InProgress;
        draftState.users.totalNumberOfRecords = 0;
        draftState.users.data = [];
      });
      return newState;
    }
    case FETCH_USERS_LIST_SUCCESS: {
      const { data, totalNumberOfRecords } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.users.loading = LoadState.Loaded;
        draftState.users.totalNumberOfRecords = totalNumberOfRecords;
        draftState.users.data = data;
      });
      return newState;
    }
    case FETCH_USERS_LIST_FAILED: {
      const newState = produce(state, (draftState) => {
        draftState.users.loading = LoadState.Failed;
        draftState.users.totalNumberOfRecords = 0;
        draftState.users.data = [];
      });
      return newState;
    }
    case FETCH_USERS_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.users.userLoading = LoadState.InProgress;
        draftState.users.user = initialUserProfileState["users"]["user"];
      });
      return newState;
    }
    case FETCH_USERS_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.users.userLoading = LoadState.Loaded;
        draftState.users.user = data;
      });
      return newState;
    }
    case FETCH_USERS_FAILED: {
      const { errorMessage } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.users.userLoading = LoadState.Failed;
        draftState.users.error = errorMessage;
      });
      return newState;
    }
    case FETCH_MY_PROFILE_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.userProfileLoading = LoadState.InProgress;
      });
      return newState;
    }
    case FETCH_MY_PROFILE_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.userProfileLoading = LoadState.Loaded;
        draftState.userProfile = data;
      });
      return newState;
    }
    case FETCH_MY_PROFILE_FAILED: {
      const newState = produce(state, (draftState) => {
        draftState.userProfileLoading = LoadState.Failed;
      });
      return newState;
    }
    case FETCH_USERS_SECURITY_GROUP_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.userSecurityGroup.loading = LoadState.InProgress;
        draftState.userSecurityGroup.data = initialSecurityGroup;
      });
      return newState;
    }
    case FETCH_USERS_SECURITY_GROUP_SUCCESS: {
      const {data} = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.userSecurityGroup.loading = LoadState.Loaded;
        draftState.userSecurityGroup.data = data;
      });
      return newState;
    }
    case FETCH_USERS_SECURITY_GROUP_FAILED: {
      const newState = produce(state, (draftState) => {
        draftState.userSecurityGroup.loading = LoadState.Failed;
        draftState.userSecurityGroup.data = initialSecurityGroup;
      });
      return newState;
    }
    case CLEAR_MY_PROFILE: {
      const newState = produce(state, (draftState) => {
        draftState.userProfileLoading = LoadState.NotLoaded;
        draftState.userProfile = initialUserProfileState["userProfile"];
      });
      return newState;
    }
    default: {
      return state;
    }
  }
};
