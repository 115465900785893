import { useSelector } from "react-redux";
import { IStoreState } from "../../../../../redux/initialStoreState";
import React from "react";
import { BeforeCreateTypes } from "../../../../tasks/BeforeCreate/BeforeCreate.types";
import { useDateFilter } from "../../../../../components/Table/hooks/useDateFilter";
import { useDispatchWrapper } from "../../../../../hooks";
import {
  downloadPoliciesCancellationListPDFAsync,
  fetchTasksListAsync,
  previewPoliciesCancellationListPDFAsync,
} from "../../../../../redux/tasks/tasksActions";
import {
  IDataTableProps,
  MobileLogoRenderType,
  RenderType,
} from "../../../../../components/Table/interfaces/IDataTableProps";
import { LoadState } from "../../../../../constants/enums";
import { Avatar, Button, Divider } from "@mui/material";
import { LeadOppStatus } from "../../../../../components/LeadOppStatus/LeadOppStatus";
import { ITask } from "../../../../../redux/tasks/task.types";
import { TaskAssignedTo } from "../../../../tasks/BeforeCreate/TaskAssignedTo";
import { StandardTableActions } from "../../../../../components/StandardTableActions/StandardTableActions";
import { StandadCard } from "../../../../../components/Cards";
import { DataTable } from "../../../../../components/Table/DataTable";
import { AddOutlined } from "@mui/icons-material";
import { CancellationDialog } from "./CancellationDialog";
import { useTableV2State } from "../../../../../components/TableV2/hooks/useTableV2State";
import {
  DataTableV2RowRenderType,
  IDataTableV2Props,
} from "../../../../../components/TableV2/interfaces/IDataTableV2Props";
import { DataTableV2 } from "../../../../../components/TableV2/DataTableV2";
import { PageContainer } from "../../../../../components/container/PageContainer";
import { PageTitleBar } from "../../../../../components/PageTitleBar/PageTitleBar";

export const PolicyCancellationList: React.FC<{
  customerId: number;
  customerPolicyId: number;
  onSaveEditClick: (taskCode: string) => void;
}> = (props) => {
  const { customerId, customerPolicyId, onSaveEditClick } = props;

  const { list, loading, totalRecords } = useSelector(
    (storeState: IStoreState) => storeState.tasks.allTasks
  );

  const [tablePagination, setTablePagination] = React.useState({
    pageNumber: 1,
    rowsInPerPage: 25,
  });
  const [selectedOpp, setSelectedOppr] = React.useState<{
    isAllSelected: boolean;
    leads: BeforeCreateTypes[];
  }>({
    isAllSelected: false,
    leads: [],
  });
  const [status, setStatus] = React.useState<
    BeforeCreateTypes["status"] | "-1"
  >("-1");

  const [downloading, setDownloading] = React.useState<boolean>(false);

  const {
    state: { dateState, searchState, columnsConfig },
    setDateState,
    setSearchState,
    setColumnVisibility,
  } = useTableV2State({
    filtersInitialState: {
      defaultDateRange: "lastWeek",
      selectedTab: "-1",
    },
  });
  const dispatch = useDispatchWrapper();

  const [open, setOpen] = React.useState<boolean>(false);
  const handleClose = () => {
    setOpen((prev) => !prev);
  };

  const changeNextStep = (taskCode: string) => {
    onSaveEditClick(taskCode);
  };

  const fetchList = async () => {
    dispatch(
      fetchTasksListAsync(
        tablePagination.pageNumber,
        tablePagination.rowsInPerPage,
        status,
        dateState.dates,
        searchState,
        customerPolicyId,
        "Cancellation/Reinstatement",
        undefined
      )
    );
  };

  React.useEffect(() => {
    fetchList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tablePagination, dateState.dates, status]);

  const opportunitesTableProps: IDataTableV2Props = {
    isPagination: true,
    totalRecords: totalRecords,
    rowsPerPageOptions: tablePagination.rowsInPerPage,
    isDataLoading: loading === LoadState.InProgress,
    selectionMode: "multiple",
    uniqueRowKeyName: "id",
    tableCommandBarProps: {
      preDefinedPlugins: {},
      leftItems: {
        customPlugins: [],
      },
      rightItems: {
        customPlugins: [],
      },
    },

    masterColumns: [
      {
        key: "view",
        headerName: "Actions",
        fieldName: "",
        renderType: DataTableV2RowRenderType.CUSTOM_RENDER,
        headingAlign: 'center',
        isFirstColumnSticky: true,
        onRowCellRender: (value, row) => {
          return (
            <StandardTableActions
              // onViewClick={() => setOpenView(row as ITask)}
              onEditClick={() => {
                onSaveEditClick(row.task_code);
              }}
              onDownloadPreview={() => {
                dispatch(
                  previewPoliciesCancellationListPDFAsync(
                    row.task_code,
                    () => {}
                  )
                );
              }}
              onDownLoadClick={() => {
                setDownloading(true);
                dispatch(
                  downloadPoliciesCancellationListPDFAsync(
                    row.task_code,
                    () => {
                      setDownloading(false);
                    }
                  )
                );
              }}
            />
          );
        },
      },
      {
        key: "task_status",
        headerName: "Status",
        fieldName: "task_status",
        renderType: DataTableV2RowRenderType.CUSTOM_RENDER,
        onRowCellRender: (value, row: ITask) => {
          return <LeadOppStatus status={row.status} />;
        },
      },

      {
        key: "task_type",
        headerName: "Transaction",
        fieldName: "task_type",
        renderType: DataTableV2RowRenderType.TEXT_DARK,
      },
      {
        key: "task_sub_type",
        headerName: "Sub-Type",
        fieldName: "task_sub_type",
        renderType: DataTableV2RowRenderType.TEXT_DARK,
      },

      {
        key: "task_code",
        headerName: "Task Code",
        fieldName: "task_code",
        renderType: DataTableV2RowRenderType.CHIP_SUCCESS,
        // onRowCellRender: (value, row: ITask) => {
        //   return <LeadOppStatus status={row.task_status} />;
        // },
      },

      {
        key: "task_created_date",
        headerName: "Date Created",
        fieldName: "task_created_date",
        renderType: DataTableV2RowRenderType.DATE,
      },
      {
        key: "task_due_date",
        headerName: "Due Date",
        fieldName: "task_due_date",
        renderType: DataTableV2RowRenderType.DATE,
      },
      {
        key: "insert_ts",
        headerName: "Date Modified",
        fieldName: "insert_ts",
        renderType: DataTableV2RowRenderType.DATE,
      },
      {
        key: "assigned_to",
        headerName: "Assigned To",
        fieldName: "",
        renderType: DataTableV2RowRenderType.CUSTOM_RENDER,
        onRowCellRender: (value, row) => {
          return <TaskAssignedTo task={row} onComplete={fetchList} />;
        },
      },

    
    ],
    rows: list,
    onPageChange: (newPageNumber: number) => {
      setTablePagination({ ...tablePagination, pageNumber: newPageNumber });
    },
    onRowsPerPageChange: (pageNumber: number, rowsPerPage: number) => {
      setTablePagination({
        pageNumber: pageNumber,
        rowsInPerPage: rowsPerPage,
      });
    },
    onSelection: (isAllSelected: boolean, rows: BeforeCreateTypes[]) => {
      setSelectedOppr({ isAllSelected: isAllSelected, leads: rows });
    },
  };

  return (
    <PageContainer description="this is innerpage">
      <PageTitleBar
        rightHeading={
          <Button
            variant="contained"
            onClick={handleClose}
            startIcon={<AddOutlined />}
          >
            Create
          </Button>
        }
      />
      <Divider sx={{ mt: 2, mb: 2 }}/>
      <DataTableV2 {...opportunitesTableProps} />

      {open && customerId && customerPolicyId && (
        <CancellationDialog

          customerPolicyId={customerPolicyId}
          open={open}
          onClose={handleClose}
          changeNextStep={changeNextStep}
        />
      )}
    </PageContainer>
  );
};
