import React from "react";
import { IExpressionTransformationProps } from "./ExpressionTransformation.types";
import { Dialog } from "../Dialogs/Dialog";
import {
  Box,
  Button,
  CircularProgress,
  Collapse,
  Divider,
  Grid,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Pagination,
  Paper,
  Stack,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import {
  ChevronRight,
  Code,
  ContentCopyOutlined,
  Edit,
  ErrorOutline,
  ExpandMore,
  Folder,
  FolderOpen,
  Functions,
} from "@mui/icons-material";

import { useSelector } from "react-redux";
import { IStoreState } from "../../redux/initialStoreState";
import { LoadState } from "../../constants/enums";
import { useDispatchWrapper } from "../../hooks";
import {
  fetchExpressionColumnsAsync,
  fetchExpressionExternalFunctionsAsync,
  fetchExpressionFunctionsAsync,
} from "../../redux/expressionTransformation/expressionTransformationActions";
import {
  IExpressTransformFunParams,
  IExpressTransformObject,
  IVariableItem,
} from "../../redux/expressionTransformation/expressionTransformation.types";

import { batch } from "react-redux";
import { useFormik } from "formik";
import CodeMirror, { ReactCodeMirrorRef } from "@uiw/react-codemirror";
import { javascript } from "@codemirror/lang-javascript";
import produce from "immer";
import { showMessage } from "../../redux/messages/messagesActions";
import { ExpressionValidateRightPanel } from "./ExpressionValidateRightPanel";
import { CreateCodeRightPanel } from "./CreateCodeRightPanel";
import { CustomTextField } from "../formsComponents";
import { formatText } from "../../helpers";

const INITIAL_CODE: IVariableItem = {
  group_code: "",
  start_index: 0,
  element_length: 0,
  has_multiple_values: 0,
  status: "ACTIVE",
  csio_edi_groupcode_definition_code: null,
};

export const ExpressionTransformation: React.FC<
  IExpressionTransformationProps
> = (props) => {
  const { open, data: mappingData, onClose, onComplete } = props;
  const { data, loading, error } = useSelector(
    (storeState: IStoreState) => storeState.expressionTransformation.funsVars
  );
  const tableName = useSelector(
    (storeState: IStoreState) =>
      storeState.expressionTransformation.selectedTableName
  );
  const {
    data: variablesList,
    loading: variablesLoading,
    error: variablesError,
    totalRecords,
  } = useSelector(
    (storeState: IStoreState) => storeState.expressionTransformation.variables
  );

  const {
    data: externalFunctionsList,
    loading: externalFunctionsLoading,
    error: externalFunctionsError,
  } = useSelector(
    (storeState: IStoreState) =>
      storeState.expressionTransformation.externalFunctions
  );

  const [tabSelected, setTabSelected] = React.useState(0);
  const dispatch = useDispatchWrapper();
  const [openValidate, setOpenValidate] = React.useState(false);
  const { values, setFieldValue, handleSubmit, setValues } = useFormik({
    initialValues: mappingData,
    validate: (values) => {},
    onSubmit: (values) => {
      setOpenValidate(true);
    },
  });
  const editorRef = React.useRef<ReactCodeMirrorRef | null>(null);
  const cursorPosRef = React.useRef<any>(0);
  const [tablePagination, setTablePagination] = React.useState({
    pageNumber: 1,
    rowsInPerPage: 20,
  });
  const [searchValue, setSearchValue] = React.useState("");
  const [openCreateCode, setOpenCreateCode] =
    React.useState<IVariableItem | null>(null);

  const insertTextAtCursor = (text: string) => {
    if (!editorRef.current) {
      return;
    }
    const editor = editorRef.current.view;
    if (editor) {
      const cursorPosition = editor.state.selection.main.from;
      cursorPosRef.current = cursorPosition;
      const newValues = produce(values, (draftValues) => {
        draftValues.eval_string =
          draftValues.eval_string.slice(0, cursorPosition) +
          text +
          draftValues.eval_string.slice(cursorPosition);
      });
      setValues(newValues);
      // Move the cursor position after the inserted text
      setTimeout(() => {
        // Update cursor position after state update
        editor.dispatch({
          selection: {
            anchor: cursorPosition + text.length, // Move cursor to the end of inserted text
            head: cursorPosition + text.length,
          },
        });
      }, 0);
    }
  };

  const handleLeftButtonsClick = (value: string) => {
    insertTextAtCursor(value);
  };

  const handleClear = () => {
    setFieldValue("eval_string", "");
  };

  const handleVariableClick = (item: IVariableItem) => () => {
    const snippet = item.has_multiple_values
      ? `CODE["${item.group_code}"][index:number]`
      : `CODE["${item.group_code}"]`;

    insertTextAtCursor(snippet);
  };

  const copyToClipboard = (item: string) => async () => {
    // console.log(field)

    await navigator.clipboard.writeText(item);
    dispatch(
      showMessage({
        type: "success",
        displayAs: "snackbar",
        message: "Text copied successfully!",
      })
    );
  };

  const handleFuncClick = (
    mainKey: string,
    subKey: string,
    returnType: any,
    params: IExpressTransformFunParams[]
  ) => {
    if (!editorRef.current) {
      return;
    }
    const editor = editorRef.current.view;
   if(editor){
    let content = "";
    const cursorPosition = editor?.state.selection.main.from;
    cursorPosRef.current = cursorPosition;
  
    const newValues = produce(values, (draftValues) => {
      if(!values.libraries){
        draftValues.libraries = [];
      }
      const index = draftValues.libraries.indexOf(mainKey);
      if (index === -1) {
        draftValues.libraries.push(mainKey);
      }
      content = content + " " + subKey + "(";
      for (const parm of params) {
        content = content + parm.name + ":" + parm.type + ",";
      }
      content = content.slice(0, -1) + ")";

      draftValues.eval_string =
      draftValues.eval_string.slice(0, cursorPosition) +
        content +
        draftValues.eval_string.slice(cursorPosition);

        
    });
    setValues(newValues);
    setTimeout(() => {
      // Update cursor position after state update
      editor.dispatch({
        selection: {
          anchor: cursorPosition + content.length, // Move cursor to the end of inserted content
          head: cursorPosition + content.length,
        },
      });
    }, 0);
   }
  };

  const handleExternalFuncClick = (
    mainKey: string,
    subKey: string,
    returnType: any,
    params: IExpressTransformFunParams[]
  ) => {
    const newValues = produce(values, (draftValues) => {
      if (values.eval_string && values.eval_string.length > 0) {
        draftValues.eval_string = draftValues.eval_string + "\n";
      }
      draftValues.eval_string = `${draftValues.eval_string}${mainKey}.${subKey}(`;
      for (const parm of params) {
        draftValues.eval_string =
          draftValues.eval_string + parm.name + ":" + parm.type + ",";
      }
      draftValues.eval_string = draftValues.eval_string.slice(0, -1) + ")";
    });
    setValues(newValues);
  };

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setTablePagination({ ...tablePagination, pageNumber: value });
  };

  const handleSearchKeyDown = (event: any) => {
    if (event && event.key === "Enter") {
      dispatch(
        fetchExpressionColumnsAsync(
          searchValue,
          tablePagination.pageNumber,
          tablePagination.rowsInPerPage
        )
      );
    }
  };
  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.currentTarget.value;
    // debounceFn(value);
    setSearchValue(value);
  };

  const handleCreateCodeComplete = () => {
    setOpenCreateCode(null);
    dispatch(
      fetchExpressionColumnsAsync(
        "",
        tablePagination.pageNumber,
        tablePagination.rowsInPerPage
      )
    );
  };

  React.useEffect(() => {
    batch(() => {
      dispatch(fetchExpressionFunctionsAsync());
      dispatch(fetchExpressionExternalFunctionsAsync());
    });
  }, []);

  React.useEffect(() => {
    dispatch(
      fetchExpressionColumnsAsync(
        searchValue,
        tablePagination.pageNumber,
        tablePagination.rowsInPerPage
      )
    );
  }, [tablePagination]);

  const [openFolder, setOpenFolder] = React.useState<string | null>(null);

  const handleOpenFolder = (key: string) => () => {
    const previousValue = openFolder;
    if (previousValue === key) {
      setOpenFolder(null);
    } else {
      setOpenFolder(key);
    }
  };

  return (
    <>
      <Dialog
        open={open}
        title={`Expression - ${formatText(mappingData.column_name || "")} (${
          mappingData.column_name
        })`}
        size="lg"
        fullScreen
        onClose={onClose}
      >
        <Grid container spacing={1}>
          <Grid item xs={3}>
            <Paper
              variant="outlined"
              sx={{ height: "90vh", overflowY: "auto" }}
            >
              <List>
                <ListItemButton onClick={handleOpenFolder("Functions")}>
                  {openFolder === "Functions" ? (
                    <ExpandMore color="primary" />
                  ) : (
                    <ChevronRight color="primary" />
                  )}
                  <ListItemIcon>
                    <FolderOpen color="primary" />
                  </ListItemIcon>
                  <ListItemText
                    primary={"Functions"}
                    sx={{
                      "& span": {
                        fontSize: "1rem",
                        fontWeight: 500,
                      },
                    }}
                  />
                </ListItemButton>
                <Collapse
                  in={openFolder === "Functions"}
                  timeout="auto"
                  unmountOnExit
                >
                  {loading === LoadState.InProgress && (
                    <Stack
                      minHeight={"300px"}
                      direction={"column"}
                      justifyContent={"center"}
                      alignItems={"center"}
                    >
                      <Box
                        display={"flex"}
                        flexDirection={"column"}
                        justifyContent={"center"}
                        alignItems={"center"}
                      >
                        <CircularProgress size={25} sx={{ mb: 2 }} />
                        <Typography variant="h5" fontWeight={500}>
                          Loading, please wait...
                        </Typography>
                      </Box>
                    </Stack>
                  )}
                  {loading === LoadState.Failed && (
                    <Box
                      minHeight={100}
                      display={"flex"}
                      flexDirection={"column"}
                      justifyContent={"center"}
                      alignItems={"center"}
                    >
                      <Box display={"flex"} justifyContent={"center"} mb={1}>
                        <ErrorOutline />
                      </Box>
                      <Box
                        display={"flex"}
                        justifyContent={"center"}
                        width={"80%"}
                      >
                        <Typography
                          variant="body1"
                          textAlign={"center"}
                          fontWeight={600}
                        >
                          {error}
                        </Typography>
                      </Box>
                    </Box>
                  )}
                  {loading === LoadState.Loaded &&
                    Object.keys(data).map((key, index) => {
                      const dataObject = data[key];
                      return (
                        <TreeListItem
                          key={index}
                          mainKey={key}
                          item={dataObject}
                          onClick={handleFuncClick}
                        />
                      );
                    })}
                </Collapse>
                <ListItemButton
                  onClick={handleOpenFolder("External Functions")}
                >
                  {openFolder === "External Functions" ? (
                    <ExpandMore color="primary" />
                  ) : (
                    <ChevronRight color="primary" />
                  )}
                  <ListItemIcon>
                    <FolderOpen color="primary" />
                  </ListItemIcon>
                  <ListItemText
                    primary={"External Functions"}
                    sx={{
                      "& span": {
                        fontSize: "1rem",
                        fontWeight: 500,
                      },
                    }}
                  />
                </ListItemButton>
                <Collapse
                  in={openFolder === "External Functions"}
                  timeout="auto"
                  unmountOnExit
                >
                  {externalFunctionsLoading === LoadState.InProgress && (
                    <Stack
                      minHeight={"300px"}
                      direction={"column"}
                      justifyContent={"center"}
                      alignItems={"center"}
                    >
                      <Box
                        display={"flex"}
                        flexDirection={"column"}
                        justifyContent={"center"}
                        alignItems={"center"}
                      >
                        <CircularProgress size={25} sx={{ mb: 2 }} />
                        <Typography variant="h5" fontWeight={500}>
                          Loading, please wait...
                        </Typography>
                      </Box>
                    </Stack>
                  )}
                  {externalFunctionsLoading === LoadState.Failed && (
                    <Box
                      minHeight={100}
                      display={"flex"}
                      flexDirection={"column"}
                      justifyContent={"center"}
                      alignItems={"center"}
                    >
                      <Box display={"flex"} justifyContent={"center"} mb={1}>
                        <ErrorOutline />
                      </Box>
                      <Box
                        display={"flex"}
                        justifyContent={"center"}
                        width={"80%"}
                      >
                        <Typography
                          variant="body1"
                          textAlign={"center"}
                          fontWeight={600}
                        >
                          {error}
                        </Typography>
                      </Box>
                    </Box>
                  )}
                  {externalFunctionsLoading === LoadState.Loaded &&
                    Object.keys(externalFunctionsList).map((key, index) => {
                      const dataObject = externalFunctionsList[key];
                      return (
                        <TreeListItem
                          key={index}
                          mainKey={key}
                          item={dataObject}
                          onClick={handleExternalFuncClick}
                        />
                      );
                    })}
                </Collapse>

                <ListItemButton onClick={handleOpenFolder("Variables")}>
                  {openFolder === "Variables" ? (
                    <ExpandMore color="primary" />
                  ) : (
                    <ChevronRight color="primary" />
                  )}
                  <ListItemIcon>
                    <FolderOpen color="primary" />
                  </ListItemIcon>
                  <ListItemText
                    primary={"Variables"}
                    sx={{
                      "& span": {
                        fontSize: "1rem",
                        fontWeight: 500,
                      },
                    }}
                  />
                </ListItemButton>
                <Collapse
                  in={openFolder === "Variables"}
                  timeout="auto"
                  unmountOnExit
                >
                  <Divider />
                  <Stack
                    direction={"row"}
                    justifyContent={"center"}
                    mt={1}
                    mb={1}
                    pl={2}
                    pr={2}
                  >
                    <CustomTextField
                      fullWidth
                      value={searchValue}
                      placeholder="Enter after typing"
                      onChange={handleSearchChange}
                      onKeyDown={handleSearchKeyDown}
                    />

                    {/* <CustomSearch >
                    <SearchIconWrapper>
                      <SearchIcon color="disabled" />
                    </SearchIconWrapper>
                    <StyledInputBase
                     fullWidth
                      value={searchValue}
                      placeholder="Enter after typing"
                      inputProps={{ "aria-label": "search" }}
                      //onChange={handleChange}
                      //onKeyDown={handleKeyDown}
                    />
                  </CustomSearch> */}
                  </Stack>
                  <Divider />
                  {variablesLoading === LoadState.InProgress && (
                    <Stack
                      minHeight={"300px"}
                      direction={"column"}
                      justifyContent={"center"}
                      alignItems={"center"}
                    >
                      <Box
                        display={"flex"}
                        flexDirection={"column"}
                        justifyContent={"center"}
                        alignItems={"center"}
                      >
                        <CircularProgress size={25} sx={{ mb: 2 }} />
                        <Typography variant="h5" fontWeight={500}>
                          Loading, please wait...
                        </Typography>
                      </Box>
                    </Stack>
                  )}
                  {variablesLoading === LoadState.Failed && (
                    <Box
                      minHeight={400}
                      display={"flex"}
                      flexDirection={"column"}
                      justifyContent={"center"}
                      alignItems={"center"}
                    >
                      <Box display={"flex"} justifyContent={"center"} mb={1}>
                        <ErrorOutline />
                      </Box>
                      <Box
                        display={"flex"}
                        justifyContent={"center"}
                        width={"100%"}
                      >
                        <Typography
                          variant="body1"
                          textAlign={"center"}
                          fontWeight={600}
                        >
                          {variablesError}
                        </Typography>
                      </Box>
                    </Box>
                  )}
                  {variablesLoading === LoadState.Loaded &&
                    variablesList.map((item) => {
                      return (
                        <Stack
                          direction={"row"}
                          justifyContent={"space-between"}
                          alignItems={"center"}
                          pr={1}
                          spacing={0.5}
                        >
                          <ListItemButton onClick={handleVariableClick(item)}>
                            <ListItemText
                              primary={item.group_code}
                              sx={{
                                "& span": {
                                  fontSize: "0.9rem",
                                  fontWeight: 500,
                                  textOverflow: "ellipsis",
                                  width: "80%",
                                  overflow: "hidden",
                                  wordWrap: "nowrap",
                                },
                              }}
                            />
                          </ListItemButton>
                          <Box
                            sx={{ cursor: "pointer" }}
                            onClick={() => setOpenCreateCode(item)}
                          >
                            <Edit color="primary" />
                          </Box>
                          <ContentCopyOutlined
                            color={"primary"}
                            sx={{ cursor: "pointer" }}
                            onClick={copyToClipboard(item.group_code)}
                          />
                        </Stack>
                      );
                    })}
                  {totalRecords > 0 && (
                    <Stack direction={"row"} justifyContent={"center"}>
                      <Pagination
                        variant="text"
                        count={Math.ceil(
                          totalRecords / tablePagination.rowsInPerPage
                        )}
                        onChange={handlePageChange}
                      />
                    </Stack>
                  )}
                </Collapse>
              </List>
              {/* <Tabs
                value={tabSelected}
                variant="scrollable"
                onChange={handleTabChange}
              >
                <Tab
                  label="Functions"
                  sx={{  fontWeight: 500 }}
                  iconPosition="start"
                  value={0}
                  icon={<FunctionsOutlined />}
                  {...a11yProps(0)}
                />
                 <Tab
                  label="External Functions"
                  sx={{  fontWeight: 500 }}
                  iconPosition="start"
                  value={1}
                  icon={<FunctionsOutlined />}
                  {...a11yProps(1)}
                />
                <Tab
                  label="Variables"
                  sx={{ fontSize: "1.1rem", fontWeight: 500 }}
                  iconPosition="start"
                  icon={<Code />}
                  value={2}
                  {...a11yProps(2)}
                />
              </Tabs> */}
              <List></List>
            </Paper>
          </Grid>
          <Grid item xs={9}>
            <Paper
              variant="outlined"
              sx={{ height: "60vh", width: "100%", overflow: "auto" }}
            >
              <CodeMirror
                ref={editorRef}
                value={values.eval_string || ""}
                height="60vh"
                theme="dark"
                extensions={[javascript()]}
                autoFocus={true}
                onChange={(value: any) =>
                  setFieldValue("eval_string", value || "")
                }
              />
              {/* <Editor
                height="100%"
                width={"100%"}
                defaultLanguage="javascript"
                value={values.eval_string || ""}
                theme="vs-dark" // Use the dark theme
                onChange={(value) => setFieldValue("eval_string", value || "")}
                options={{
                  automaticLayout: true, // Adjust layout when the window is resized
                  fontSize: 18, // Set font size
                }}
              /> */}
            </Paper>
            <Grid container spacing={1} mt={1}>
              <Grid item xs={9}>
                <Paper variant="outlined" sx={{ height: "100%", padding: 2 }}>
                  <Grid container spacing={1}>
                    {[
                      { label: "AND", value: "&" },
                      { label: "OR", value: "||" },
                      { label: "NOT", value: "!" },
                    ].map((item) => {
                      return (
                        <Grid item xs={4}>
                          <ExpenssionButton
                            label={item.label}
                            value={item.value}
                            onClick={handleLeftButtonsClick}
                          />
                        </Grid>
                      );
                    })}
                  </Grid>
                  <Paper variant="outlined" sx={{ mt: 1 }}>
                    <Grid container spacing={1}>
                      {[
                        { label: "(", value: "(" },
                        { label: ")", value: ")" },
                        { label: "<", value: "<" },
                        { label: ">", value: ">" },
                        { label: "=", value: "=" },
                        { label: "!=", value: "!=" },
                        { label: "+", value: "+" },
                        { label: "-", value: "-" },
                        { label: "<=", value: "<=" },
                        { label: ">=", value: ">=" },
                        { label: "%", value: "%" },
                        { label: "||", value: "||" },
                        { label: "*", value: "*" },
                        { label: "/", value: "/" },
                        { label: "'", value: "'" },
                        { label: `"`, value: '"' },
                        { label: `,`, value: "," },
                        { label: `:`, value: ":" },
                      ].map((item) => {
                        return (
                          <Grid item xs={2}>
                            <ExpenssionButton
                              label={item.label}
                              value={item.value}
                              onClick={handleLeftButtonsClick}
                            />
                          </Grid>
                        );
                      })}
                    </Grid>
                    <Stack
                      direction={"row"}
                      flexWrap={"wrap"}
                      spacing={1}
                    ></Stack>
                  </Paper>
                  <Stack
                    direction={"row"}
                    flexWrap={"wrap"}
                    spacing={1}
                  ></Stack>
                </Paper>
              </Grid>
              <Grid item xs={3}>
                <Paper variant="outlined" sx={{ p: 2, height: "100%" }}>
                  <Stack
                    direction={"column"}
                    justifyContent={"center"}
                    spacing={1}
                  >
                    <Button
                      variant="contained"
                      color="error"
                      fullWidth
                      sx={{ fontSize: "1.1rem" }}
                      onClick={handleClear}
                    >
                      Clear
                    </Button>

                    <Button
                      variant="contained"
                      fullWidth
                      sx={{ fontSize: "1.1rem" }}
                      onClick={() => handleSubmit({} as any)}
                    >
                      Validate & Save
                    </Button>
                    <Button
                      variant="contained"
                      fullWidth
                      sx={{ fontSize: "1.1rem" }}
                    >
                      Cancel
                    </Button>

                    <Button
                      variant="contained"
                      color="warning"
                      sx={{
                        color: "#000",
                        fontWeight: 500,
                        fontSize: "1.1rem",
                      }}
                      fullWidth
                      onClick={() => setOpenCreateCode(INITIAL_CODE)}
                    >
                      Create Variable
                    </Button>
                  </Stack>
                </Paper>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Dialog>
      {openValidate && (
        <ExpressionValidateRightPanel
          open={open}
          data={values}
          onClose={() => setOpenValidate(false)}
          onComplete={() => {
            setOpenValidate(false);
            onComplete();
          }}
        />
      )}
      {openCreateCode && (
        <CreateCodeRightPanel
          open={true}
          data={openCreateCode}
          onComplete={handleCreateCodeComplete}
          onClose={() => setOpenCreateCode(null)}
        />
      )}
    </>
  );
};

// const TreeListItemForExternal: React.FC<{
//   item: IExpressionExternalFunsObject;
//   mainKey: string;
//   onClick: (
//     mainKey: string,
//     returnType: any,
//     params: IExpressTransformFunParams[]
//   ) => void;
// }> = ({ item, mainKey, onClick }) => {

//   return (
//     <>
//       <ListItemButton
//         onClick={() => onClick(mainKey, item.extractValue.return, item.extractValue.params)}
//       >
//         <ListItemIcon>
//                     <Functions />
//                   </ListItemIcon>
//         <ListItemText
//           primary={mainKey}
//           sx={{
//             "& span": {
//               fontSize: "0.9rem",
//               fontWeight: 500,
//             },
//           }}
//         />
//       </ListItemButton>
//     </>
//   );
// };

const TreeListItem: React.FC<{
  item: IExpressTransformObject;
  mainKey: string;
  onClick: (
    mainKey: string,
    subKey: string,
    returnType: any,
    params: IExpressTransformFunParams[]
  ) => void;
}> = ({ item, mainKey, onClick }) => {
  const [open, setOpen] = React.useState(false);
  const keys = Object.keys(item);

  const handleToogle = () => {
    setOpen(!open);
  };

  return (
    <>
      <ListItemButton sx={{ pl: 4 }} onClick={handleToogle}>
        {open ? <ExpandMore /> : <ChevronRight />}
        <ListItemIcon>
          <Folder color="warning" />
        </ListItemIcon>
        <ListItemText
          primary={mainKey}
          sx={{
            "& span": {
              fontSize: "0.9rem",
              fontWeight: 500,
            },
          }}
        />
      </ListItemButton>

      {keys.length > 0 && (
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {keys.map((childKey, index) => {
              const params = item[childKey].params;
              return (
                <ListItemButton
                  key={childKey}
                  sx={{ pl: 6 }}
                  onClick={() =>
                    onClick(mainKey, childKey, item[childKey].return, params)
                  }
                >
                  <ListItemIcon>
                    <Functions />
                  </ListItemIcon>
                  <ListItemText primary={childKey} />
                </ListItemButton>
              );
            })}
          </List>
        </Collapse>
      )}
    </>
  );
};

const ExpenssionButton: React.FC<{
  label: string;
  value: string;
  onClick: (value: string) => void;
}> = ({ label, value, onClick }) => {
  return (
    <Button
      variant="contained"
      fullWidth
      sx={{ fontSize: "1.1rem" }}
      onClick={() => onClick(value)}
    >
      {label}
    </Button>
  );
};
